import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 52,
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
      <svg className={classes.svg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 186.82 181.65">
        <path fill="#006fb9" d="M174.52,11.17c-.15-.66-.66-1.17-1.28-1.35-21.44-5.23-70.96,13.43-97.78,40.25-4.79,4.76-9.15,9.92-13.03,15.41-8.27-.73-16.54-.11-23.6,2.96-19.91,8.75-25.69,31.62-27.34,41.42-.33,1.9.95,3.73,2.89,4.06.33.04.66.07.99.04l31.95-3.51c.04,2.42.18,4.83.44,7.21.15,1.65.91,3.22,2.09,4.39l12.37,12.33c1.17,1.17,2.74,1.94,4.39,2.09,2.38.26,4.76.4,7.17.44l-3.51,31.91c-.22,1.94,1.21,3.7,3.15,3.88.33.04.66.04.95-.04,9.81-1.57,32.71-7.36,41.42-27.26,3.07-7.06,3.7-15.3,3-23.53,5.53-3.88,10.69-8.27,15.44-13.03,26.9-26.75,45.45-75.16,40.29-97.67Z"/>
        <path fill="#14bdef" d="M61.37,143.31c-2.01,2.01-5.23,2.78-9.11,3.48-8.71,1.46-16.39-6.04-14.82-14.82.59-3.33,2.38-8.01,3.48-9.11.62-.62.66-1.65.04-2.27-.37-.37-.84-.55-1.35-.48-4.87.59-9.4,2.82-12.84,6.26-8.64,8.64-9.44,40.58-9.44,40.58,0,0,31.98-.81,40.58-9.44,3.48-3.48,5.67-7.98,6.26-12.88.15-1.5-1.72-2.42-2.78-1.32Z"/>
        <path fill="#fff" d="M109.6,55.76l14.69-8.24-1.65-16.76,12.38,11.42,15.43-6.75-7.04,15.3,11.18,12.59-16.72-1.97-8.52,14.53-3.3-16.51-16.45-3.61Z"/>
      </svg>
  );
};

export default LogoIcon;
