import { createTheme, genPageTheme, darkTheme, shapes } from '@backstage/theme';

const bluesoftTheme = createTheme({
  palette: {
    ...darkTheme.palette,
    primary: {
      main: '#9cc9ff',
    },
    secondary: {
      main: '#D35400',
    },
    error: {
      main: '#E74C3C',
    },
    warning: {
      main: '#F39C12',
    },
    info: {
      main: '#3498DB',
    },
    success: {
      main: '#2ECC71',
    },
    background: {
      default: '#333333',
      paper: '#424242',
    },
    banner: {
      info: '#3498DB',
      error: '#E74C3C',
      text: '#9f9f9f',
      link: '#005792',
    },
    errorBackground: '#FADBD8',
    warningBackground: '#FAE5D3',
    infoBackground: '#D6EAF8',
    navigation: {
      background: '#424242',
      indicator: '#F39C12',
      color: '#CACFD2',
      selectedColor: '#FFFFFF',
    },
  },
  defaultPageTheme: 'home',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  pageTheme: {
    home: genPageTheme({ colors: ['#005792', '#333333'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#9cc9ff', '#333333'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#005792', '#333333'], shape: shapes.round }),
    service: genPageTheme({
      colors: ['#9cc9ff', '#333333'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#9cc9ff', '#333333'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#9cc9ff', '#333333'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#9cc9ff', '#333333'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#9cc9ff', '#333333'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#9cc9ff', '#333333'], shape: shapes.wave }),
  },
});

export default bluesoftTheme;
