import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 60,
    marginTop: 8,
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
        <svg className={classes.svg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 360">
        <g>
            <g>
            <path fill="#fff" d="M303.98,187.81c-5.67,4.44-12.86,6.74-21.75,6.74-12.71,0-23.74-3.83-33.08-11.33l8.58-13.17c7.97,5.82,16.24,8.73,24.97,8.73,7.5,0,11.33-2.6,11.33-7.81,0-3.83-3.83-7.35-11.33-10.57l-9.8-4.14c-13.48-5.67-20.22-13.78-20.22-24.35,0-6.59,2.61-11.95,7.66-16.24,5.21-4.44,12.1-6.59,20.68-6.59,11.49,0,20.98,3.22,28.79,9.5l-9.04,13.02c-5.97-4.59-12.56-6.89-19.91-6.89-6.74,0-10.41,2.76-10.41,7.04,0,3.68,3.37,6.89,9.96,9.65l9.8,4.14c14.55,6.13,22.21,13.02,22.21,24.81,0,7.04-2.76,12.86-8.43,17.46Z"/>
            <path fill="#fff" d="M339.5,187.05c-5.05-4.75-7.66-11.49-7.66-19.91v-40.13h-13.48v-15.93l3.98-.15c7.81-.46,10.41-3.22,10.41-12.25v-9.65h17.31v21.9h22.97v16.08h-22.97v36.91c0,9.04,4.9,13.63,14.55,13.63,3.06,0,5.97-.15,8.73-.46v14.86c-4.29,1.22-8.58,1.84-12.86,1.84-9.19,0-16.24-2.3-20.98-6.74Z"/>
            <path fill="#fff" d="M388.5,187.66c-5.36-4.6-8.12-10.88-8.12-18.99s3.06-13.94,9.34-18.68c6.28-4.75,14.24-7.2,23.89-7.2,7.35,0,13.78,1.99,19.15,5.82v-5.82c0-12.71-5.67-17.61-17.31-17.61-8.12,0-16.08,2.6-24.04,7.66l-6.89-13.78c10.26-6.59,21.13-9.96,32.62-9.96,12.25,0,21.13,3.06,26.8,9.34,4.6,5.05,6.89,13.32,6.89,24.51v49.78h-16.08l-.61-9.5c-5.97,7.5-14.4,11.18-25.42,11.18-7.97,0-14.7-2.3-20.22-6.74ZM432.77,168.67v-2.76c-1.68-5.67-8.27-9.95-17.61-9.95-4.44,0-8.42,1.22-11.95,3.52-3.37,2.3-5.05,5.05-5.05,8.58,0,7.5,7.2,12.4,16.85,12.4,11.03,0,17.77-6.28,17.77-11.79Z"/>
            <path fill="#fff" d="M485.75,122.41c6.43-8.42,14.4-12.56,23.74-12.56,1.38,0,2.76.15,4.14.46v16.39c-1.38-.15-2.61-.31-3.37-.31-7.05,0-12.56,1.68-16.39,5.21-4.44,3.83-6.58,9.8-6.58,17.92v43.19h-18.23v-81.79h16.39l.3,11.49Z"/>
            <path fill="#fff" d="M543.17,180.77l-.46,11.95h-16.39v-124.21h18.23v53.14c6.58-8.27,15.62-12.4,27.11-12.4s20.83,4.13,28.03,12.4c7.35,8.27,11.03,18.23,11.03,30.02s-3.68,21.9-10.87,30.33c-7.2,8.27-16.69,12.41-28.49,12.41s-21.29-4.59-28.18-13.63ZM585.29,170.81c4.59-4.9,6.89-11.33,6.89-19.15s-2.3-13.78-6.74-18.68c-4.44-5.05-10.26-7.5-17.61-7.5-14.55,0-24.04,10.41-24.04,26.19,0,7.81,2.3,14.24,6.74,19.15,4.59,4.75,10.41,7.2,17.31,7.2s13.02-2.45,17.46-7.2Z"/>
            <path fill="#fff" d="M632.91,182.76c-8.43-8.12-12.71-18.53-12.71-30.94s4.14-22.51,12.56-30.63c8.27-8.12,18.53-12.1,30.79-12.1s22.51,3.98,30.94,12.1,12.56,18.23,12.56,30.63-4.29,22.67-12.86,30.78c-8.43,7.96-18.69,11.95-30.79,11.95s-22.36-3.98-30.48-11.79ZM681.61,132.98c-5.05-5.05-11.03-7.5-18.07-7.5s-13.02,2.45-17.77,7.35c-4.9,4.9-7.35,11.18-7.35,18.99,0,15.77,9.8,26.19,25.12,26.19,7.66,0,13.78-2.45,18.38-7.35,4.59-5.05,6.89-11.33,6.89-18.84s-2.45-14.09-7.2-18.84Z"/>
            <path fill="#fff" d="M729.23,182.76c-8.43-8.12-12.71-18.53-12.71-30.94s4.14-22.51,12.56-30.63c8.27-8.12,18.53-12.1,30.79-12.1s22.51,3.98,30.94,12.1c8.42,8.12,12.56,18.23,12.56,30.63s-4.29,22.67-12.86,30.78c-8.43,7.96-18.69,11.95-30.79,11.95s-22.36-3.98-30.48-11.79ZM777.93,132.98c-5.05-5.05-11.03-7.5-18.07-7.5s-13.02,2.45-17.77,7.35c-4.9,4.9-7.35,11.18-7.35,18.99,0,15.77,9.8,26.19,25.12,26.19,7.66,0,13.78-2.45,18.38-7.35,4.59-5.05,6.89-11.33,6.89-18.84s-2.45-14.09-7.2-18.84Z"/>
            <path fill="#fff" d="M864.45,187.81c-5.67,4.44-12.86,6.74-21.75,6.74-12.71,0-23.74-3.83-33.08-11.33l8.58-13.17c7.97,5.82,16.24,8.73,24.97,8.73,7.5,0,11.33-2.6,11.33-7.81,0-3.83-3.83-7.35-11.33-10.57l-9.8-4.14c-13.48-5.67-20.22-13.78-20.22-24.35,0-6.59,2.61-11.95,7.66-16.24,5.21-4.44,12.1-6.59,20.68-6.59,11.49,0,20.98,3.22,28.79,9.5l-9.04,13.02c-5.97-4.59-12.56-6.89-19.91-6.89-6.74,0-10.41,2.76-10.41,7.04,0,3.68,3.37,6.89,9.96,9.65l9.8,4.14c14.55,6.13,22.21,13.02,22.21,24.81,0,7.04-2.76,12.86-8.43,17.46Z"/>
            <path fill="#fff" d="M899.97,187.05c-5.05-4.75-7.66-11.49-7.66-19.91v-40.13h-13.48v-15.93l3.98-.15c7.81-.46,10.41-3.22,10.41-12.25v-9.65h17.31v21.9h22.97v16.08h-22.97v36.91c0,9.04,4.9,13.63,14.55,13.63,3.06,0,5.97-.15,8.73-.46v14.86c-4.29,1.22-8.58,1.84-12.86,1.84-9.19,0-16.24-2.3-20.98-6.74Z"/>
            </g>
            <g>
            <path fill="#fff" d="M767.85,238.68h-6.68v-6.48h6.68v-4.76c0-7.14,3.84-11.25,11.91-11.25,2.12,0,3.7.26,5.09.66v6.62c-.73-.2-1.79-.33-2.91-.33-3.9,0-5.16,1.39-5.16,4.76v4.3h8.07v6.48h-8.07v29.04h-8.93v-29.04Z"/>
            <path fill="#fff" d="M787.57,249.99c0-10.58,6.48-18.52,18.26-18.52s18.25,7.94,18.25,18.52-6.48,18.46-18.25,18.46-18.26-7.94-18.26-18.46ZM796.63,249.99c0,6.55,3.04,11.58,9.2,11.58s9.19-5.03,9.19-11.58-3.04-11.64-9.19-11.64-9.2,5.03-9.2,11.64Z"/>
            <path fill="#fff" d="M830.97,232.2h8.4l.2,5.49h.13c2.18-4.1,5.29-6.22,8.93-6.22,1.26,0,2.32.26,3.24.6v7.81c-.73-.2-2.05-.4-3.37-.4-5.62,0-8.6,3.71-8.6,7.87v20.37h-8.93v-35.52Z"/>
            <path fill="#fff" d="M906.52,255.28h-21.17l-4.43,12.44h-9.59l19.12-50.8h11.31l19.12,50.8h-9.92l-4.43-12.44ZM903.8,247.74l-7.81-21.83h-.13l-7.81,21.83h15.74Z"/>
            <path fill="#fff" d="M925.9,267.72v-50.8h9.39v50.8h-9.39Z"/>
            </g>
        </g>
        <g>
            <path fill="#006fb9" d="M209.39,31.16c-.15-.66-.66-1.17-1.28-1.35-21.44-5.23-70.96,13.43-97.78,40.25-4.79,4.76-9.15,9.92-13.03,15.41-8.27-.73-16.54-.11-23.6,2.96-19.91,8.75-25.69,31.62-27.34,41.42-.33,1.9.95,3.73,2.89,4.06.33.04.66.07.99.04l31.95-3.51c.04,2.42.18,4.83.44,7.21.15,1.65.91,3.22,2.09,4.39l12.37,12.33c1.17,1.17,2.74,1.94,4.39,2.09,2.38.26,4.76.4,7.17.44l-3.51,31.91c-.22,1.94,1.21,3.7,3.15,3.88.33.04.66.04.95-.04,9.81-1.57,32.71-7.36,41.42-27.26,3.07-7.06,3.7-15.3,3-23.53,5.53-3.88,10.69-8.27,15.44-13.03,26.9-26.75,45.45-75.16,40.29-97.67Z"/>
            <path fill="#14bdef" d="M96.25,163.3c-2.01,2.01-5.23,2.78-9.11,3.48-8.71,1.46-16.39-6.04-14.82-14.82.59-3.33,2.38-8.01,3.48-9.11.62-.62.66-1.65.04-2.27-.37-.37-.84-.55-1.35-.48-4.87.59-9.4,2.82-12.84,6.26-8.64,8.64-9.44,40.58-9.44,40.58,0,0,31.98-.81,40.58-9.44,3.48-3.48,5.67-7.98,6.26-12.88.15-1.5-1.72-2.42-2.78-1.32Z"/>
            <path fill="#fff" d="M144.48,75.75l14.69-8.24-1.65-16.76,12.38,11.42,15.43-6.75-7.04,15.3,11.18,12.59-16.72-1.97-8.52,14.53-3.3-16.51-16.45-3.61Z"/>
        </g>
        </svg>
  );
};

export default LogoFull;
